/**
 * fn will be called when the DOM is ready
 * @param {Function} fn
 */
export function ready(fn) {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

/**
 * Returns a promise that resolves when the DOM is ready
 * @returns {Promise<void>}
 */
export function whenReady() {
  return new Promise(function (resolve) {
    ready(resolve);
  });
}

export function throttle(fn, wait) {
  return function (...args) {
    const now = Date.now();
    if (now - fn.lastCall < wait) {
      return;
    }
    fn.lastCall = now;
    return fn(...args);
  }
}
