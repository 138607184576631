import { ready } from "./utils";

ready(function () {
    const list = document.querySelectorAll("[data-variable]");
    list.forEach(function (element) {
        const variable = element.getAttribute("data-variable");
        const value = element.innerText.trim();
        window[variable] = value;
        console.log(variable, value);
    });
});
